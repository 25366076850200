<template>
  <eagle-list :list-key="listKey"></eagle-list>
</template>

<script lang="babel" type="text/babel">
import listMixin from '@/components/list/mixins/listMixin'
import listConfig from './storeMemberListConfig'
export default {
  mixins: [listMixin],
  data: () => ({
    listKey: 'store-member-list',
    bindRoute: false,
    meta: {},
  }),
  methods: {
    async beforeIndex() {
      await Promise.all([])
    },
    async indexApi(params) {
      const store_id = this.$route.params.target
      const res = await this.$api.collection.storeMemberApi.index(store_id, params)
      return res.data
    },
    // 解綁
    async deleteApi(target, row) {
      const store_id = this.$route.params.target
      const member_id = row.id
      return await this.$api.collection.storeMemberApi.delete(store_id, member_id)
    },
    // 批次解綁
    async batchDeleteApi(targets, selectedData) {
      // const pivot_ids = selectedData
      //   .filter(item => item.id == targets)
      //   .map(item => item.provider_pivot.id)
      // return await this.$api.collection.storeMemberApi.batchDelete(pivot_ids)
    },
    getListConfig() {
      return listConfig
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
