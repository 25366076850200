import Vue from 'vue'
import { EagleListConfigInterface, TableDataType } from '@/components/list/types/list'
class listConfig {
  protected vm?: Vue
  protected listKey?: string

  get(vm: Vue, listKey: string): EagleListConfigInterface {
    this.vm = vm
    this.listKey = listKey
    const storeId = this.vm.$route.params.target

    return {
      // pageTitle: 'module.store-member',
      // search: 'store-member.search_keyword',
      // filter: {
      //   created_at: {
      //     label: 'data.created_at',
      //     type: 'date',
      //   },
      // },
      selectedData: row => ({
        id: row.id,
        name: row.name,
        title: row.title,
        isMaster: row.isMaster
      }),
      displayMode: 'table',
      displayModeTypes: ['card', 'table'],
      sort: [
        { label: 'data.created_at', key: 'created_at' },
      ],
      card: {
        title: row => row.name,
        subtitle: row => row.name,
        avatarPhoto: row => row.avatar,
      },
      table: {
        data: <TableDataType>[
          {
            key: 'name',
            label: 'data.manager-name',
            type: 'text',
          },
          {
            key: 'email',
            label: 'data.email',
            type: 'text',
          },
          {
            key: 'IsStoreMaster',
            label: 'data.main-manager',
            type: 'text',
            text: (row) => {
              return vm.$t(`data.main-manager.${row.IsStoreMaster}`)
            },
          },
          {
            key: 'created_at',
            label: "data.created_at",
            type: 'text'
          }
        ],
      },
      batch: {
        delete: {
          label: 'action.relieve',
          disabled: (row) => {
            return row.isMaster
          },
          targetLabel: row => row.name,
        },
      },
      metaAction: {},
      dataAction: {
        update: {
          label: 'action.edit',
          route: row => ({ name: 'member-update', params: { target: row.id } }),
          linkTarget: '_blank',
        },
        relieve: {
          label: 'action.relieve',
          create: (row) => {
            return !row.IsStoreMaster
          },
          component: () => import('modules/member/components/relieve/relieveBtn.vue')
        },
      },
    }

  }
}

export default new listConfig()
